import React from "react"
import LogoIcon from '../images/logo.svg'
import LaptopIcon from '../images/laptop-404.svg'
import { Link } from 'gatsby'
import '../styles/404.css'
import Header from "../components/Header/Header"

const NotFoundPage = () => (
    <div className='container'>
        <Header title={'Page Not Found | BDEI'}/>
        <Link to="/" className='logo-link'>
			<img src={LogoIcon} className="logo" alt='Logo'/>
		</Link>
        <img src={LaptopIcon} className="laptop" alt='Laptop Icon'/>
        <p className="header">
            Seems like the page is under the weather!
        </p>
        <p className="subtext">
            We are very sorry for the inconvenience. It looks like you are trying to access a page that is not
            available right now.
        </p>
        <Link to='/' className="home">Go Home</Link>
    </div>
)

export default NotFoundPage
